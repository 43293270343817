import React, { useEffect, useState, useRef } from "react";
import Button from "../../../atoms/Button/Button";
import Range from "./Range/Range";
import { ReactComponent as Haba } from "./trazado-413.svg";

const colors = [
  "#25c0c2",
  "#34c4c6",
  "#5cc7c8",
  "#6ad2d3",
  "#7fd8d9",
  "#96dfe0",
  "#ace5e6",
  "#c2ecec",
  "#daf2f2",
  "#edf7f7",
  "#faf8f8",
  "#fae3db",
  "#f8cebf",
  "#f6baa8",
  "#f3a28c",
  "#ef8b73",
  "#ed765c",
  "#eb624b",
  "#e84f37",
  "#e63f2a",
  "#e42d1b",
];

const BookPoint = ({
  image,
  text1,
  text2,
  text3,
  text4,
  text4Desktop,
  page,
  pageb,
  step,
  initState,
}) => {
  const [firstTime, setFirstTime] = useState(true);
  const [stateBook, setStateBook] = useState(initState);
  const [animationArrow, setAnimationArrow] = useState(false);
  const animationArrowRef = useRef(animationArrow);
  animationArrowRef.current = animationArrow;
  const [colorHaba, setColorHaba] = useState(
    initState === 1 ? colors[0] : colors[20]
  );

  useEffect(() => {
    if (!firstTime) {
      setAnimationArrow(true);
      setTimeout(() => {
        setAnimationArrow(false);
      }, 1000);
    }

    setFirstTime(false);
  }, [stateBook]);

  function renderButtonState() {
    return (
      <div className="book-point__range-section">
        <img className="book-point__range-image" src="/images/english.svg" />
        <div className="book-point__range">
          <Range
            onChange={(colorIndex) => {
              if (colorIndex > 10) {
                setStateBook(2);
              }
              if (colorIndex <= 10) {
                setStateBook(1);
              }
              setColorHaba(colors[colorIndex]);
            }}
            stateInit={initState === 1 ? 0 : 20}
          />
        </div>
        <img className="book-point__range-image" src="/images/science.svg" />
      </div>
    );
  }

  return (
    <div
      className={`book-point book-point--step-${step} book-point--statebook-${stateBook}`}
    >
      <div className="book-point__title">
        <img src={image} className="book-point__title-image" />
        <div className="book-point__title-text-container">
          <span className="book-point__text1">{text1}</span>
          <span className="book-point__text2">{text2}</span>
          {text3 && <span className="book-point__text3">{text3}</span>}
        </div>
      </div>
      <div className="book-point__content">
        <div className="book-point__images">
          <Haba
            className={"book-point__images-background"}
            style={{ fill: colorHaba }}
          />
          <div className={`book-point__images-book-container  `}>
            <img
              className={`book-point__images-book ${
                animationArrowRef.current
                  ? `animate__animated animate__jello`
                  : ""
              }`}
              src={stateBook === 1 ? page : pageb}
            />
          </div>
          <div className="book-point__button-state book-point__button-state--desktop">
            {renderButtonState()}
          </div>
        </div>
        <div className="book-point__text4-desktop">{text4Desktop}</div>

        <p className="book-point__text4">{text4}</p>

        <div className="book-point__button-state book-point__button-state--mobile">
          {renderButtonState()}
        </div>
      </div>
    </div>
  );
};

export default BookPoint;
