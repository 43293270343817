import { useEffect, useState } from "react";
import "./App.scss";
import Book from "./modules/sections/Book/Book";
import Main from "./modules/sections/Main/Main";
import Sample from "./modules/sections/Sample/Sample";
import Together from "./modules/sections/Together/Together";
import Footer from "./modules/sections/Footer/Footer";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [load, setLoad] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    setLoad(true);
  }, []);
  return (
    <div className={`app`}>
      {load && (
        <>
          <Main isMobile={isMobile} />
          <Together isMobile={isMobile} />
          <Book isMobile={isMobile} />
          <Sample isMobile={isMobile} />
          <Footer isMobile={isMobile} />
        </>
      )}
    </div>
  );
}

export default App;
