import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <a
        href="https://www.macmillaneducation.es/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/mcmillan-logo-color.png"
          className="footer__mcmillan"
        />
      </a>
      <a href="https://www.bilingualbyme.com/" target="_blank" rel="noreferrer">
        <img src="/images/byme-logo-color.png" className="footer__byme" />
      </a>
    </div>
  );
};

export default Footer;
