import React, { useEffect, useState, useRef } from "react";
import Button from "../../atoms/Button/Button";
import DialogContact from "../../components/DialogContact/DialogContact";

const Sample = ({ isMobile }) => {
  const [openContact, setOpenContact] = useState(false);
  const [animation, setAnimation] = useState(true);
  const animationRef = useRef(animation);
  animationRef.current = animation;

  useEffect(() => {
    if (!isMobile) {
      setInterval(() => {
        setAnimation(animationRef.current ? false : true);
      }, 1200);
    }
  }, []);

  return (
    <div className="sample">
      <div className="sample__grid"></div>

      <div className="sample__title">
        <span className="sample__title-text-1">SAMPLE</span>
        <span className="sample__title-text-2">materials</span>
      </div>

      <div className="sample__boxs">
        <div className="sample__box sample__box--1">
          <img
            className="sample__box-image sample__box-image--obt"
            src="/images/logo-otb.png"
            alt="imagen de adorno burbuja girando"
          />
          <div
            className={`sample__button-animation ${
              animationRef.current
                ? "animate__animated animate__rubberBand"
                : " "
            }  `}
          >
            <div className="sample__button ">
              <a
                href="https://www.bilingualbyme.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Button text="MORE INFO" />
              </a>
            </div>
          </div>
        </div>

        <div className="sample__box sample__box--2">
          <img
            className="sample__box-image"
            src="/images/logo-ltw.png"
            alt="imagen de adorno burbuja girando"
          />
          <div
            className={`sample__button-animation ${
              animationRef.current
                ? "animate__animated animate__rubberBand"
                : " "
            }  `}
          >
            <div className="sample__button ">
              <a
                href="https://www.macmillaneducation.es/iberia/lead-the-way-dive-in/"
                target="_blank"
                rel="noreferrer"
              >
                <Button text="MORE INFO" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="sample__footer">
        <div className="sample__footer-container-desktop-1">
          <span className="sample__footer-text-1">want to see</span>
          <div className="sample__footer-container sample__footer-container--1">
            <span className="sample__footer-text-2">FOR </span>
            <span className="sample__footer-text-3"> yourself how</span>
            <span className="sample__footer-text-3-desktop"> yourself</span>
          </div>
        </div>
        <div className="sample__footer-container sample__footer-container--2">
          <span className="sample__footer-text-4-desktop">how</span>
          <div className="sample__lead">
            <span className="together__lead-text">LEAD </span>
            <span className="together__lead-text">THE WAY</span>
          </div>
          <span className="sample__and">&</span>

          <div className="sample__outside">
            <span className="together__outside-text">OUTSIDE </span>
            <span className="together__outside-text">THE BOX</span>
          </div>
          <span className="sample__footer-text-5-desktop">go</span>
        </div>
        <div className="sample__footer-container sample__footer-container--3">
          <span className="sample__footer-text-5">go</span>
          <span className="sample__footer-text-6">EVEN</span>
          <span className="sample__footer-text-7-desktop"> BETTER </span>
        </div>
        <span className="sample__footer-text-7"> BETTER </span>
        <span className="sample__footer-text-8">TOGETHER?</span>
      </div>

      <div className="sample__button-contact">
        <Button
          text="CONTACT US"
          color="transparent"
          onClick={() => {
            setOpenContact(true);
          }}
        />
      </div>
      <DialogContact
        open={openContact}
        onClose={() => {
          setOpenContact(false);
        }}
      />
    </div>
  );
};

export default Sample;
