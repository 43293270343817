import React, { useState } from "react";
import { Range } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 20;

const RangeComponent = ({ onChange, stateInit }) => {
  const [values, setValues] = useState([stateInit]);

  return (
    <div className="range">
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => {
          setValues(values);
          onChange(values[0]);
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div className="range__line" ref={props.ref}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className="range__ball"
            {...props}
            style={{
              ...props.style,
            }}
          >
            {/* <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            /> */}
          </div>
        )}
      />
    </div>
  );
};

export default RangeComponent;
