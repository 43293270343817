import React, { useEffect, useState } from "react";

const Together = ({ isMobile }) => {
  return (
    <div className="together">
      <div className="together__grid"></div>

      <div className="together__container">
        <div className="together__container2">
          <div className="together__title">
            <div className="together__title-container">
              <div className="together__welcome-image-container">
                <img
                  className="together__welcome-image"
                  src="/images/welcome.png"
                  alt="imagen welcome"
                />
              </div>
              <span className="together__title-text-1 ">EVEN BETTER </span>
            </div>
            <span className="together__title-text-2 ">TOGETHER </span>
          </div>
          <div className="together__explain">
            <span className="together__explain-text-1 ">the </span>
            <span className="together__explain-text-2 ">PERFECT </span>
            <span className="together__explain-text-3 ">
              PAIRING{" "}
              <div className="together__keys ">
                <img
                  className="together__key-image"
                  src="/images/key.png"
                  alt="key"
                />
                <img
                  className="together__key-image"
                  src="/images/key.png"
                  alt="key"
                />
              </div>
            </span>
            <span className="together__explain-text-4 ">
              of elt expertise and top-notch clil content{" "}
            </span>
          </div>
          <div className="together__youtube">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/X7rvSZ7orxY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="together__covers ">
            <img
              className="together__cover-image"
              src="/images/ltw-cover.png"
              alt="ltw-cover"
            />{" "}
            <img
              className="together__cover-image"
              src="/images/obt-cover.png"
              alt="obt-cover"
            />
          </div>

          <div className="together__covers together__covers--2 ">
            <img
              className="together__cover-image"
              src="/images/obt-natural-science3.jpg"
              alt="obt-natural-science"
            />{" "}
            <img
              className="together__cover-image"
              src="/images/obt-social-science3.jpg"
              alt="obt-social-science"
            />
          </div>

          <div className="together__logos">
            <div className="together__logos-first">
              <div className="together__mcmillan-image-container">
                <img
                  className="together__mcmillan-image"
                  src="/images/mcmillan-logo.png"
                  alt="mcmillan"
                />
              </div>

              <div className="together__lead">
                <span className="together__lead-text">LEAD </span>
                <span className="together__lead-text">THE WAY</span>
              </div>
            </div>
            <span className="together__and"> &</span>

            <div className="together__byme-container">
              <div className="together__byme-image-container">
                <img
                  className="together__byme-image"
                  src="/images/logo-byme.png"
                  alt="byme"
                />
              </div>

              <div className="together__outside">
                <span className="together__outside-text">OUTSIDE </span>
                <span className="together__outside-text">THE BOX</span>
              </div>
            </div>
          </div>
          <div className="together__text">
            <p className="together__text-p">
              Two projects created in tandem and joined at the heart by one
              shared vision - to support you and your learners in bilingual
              education.
            </p>
          </div>
          <div className="together__join-us">
            <span className="together__join-us-1">Join us</span>
            <span className="together__join-and">and</span>
            <span className="together__join-us-2">join up!</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Together;
