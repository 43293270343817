import React, { useEffect, useState, useRef } from "react";

const Main = ({ isMobile }) => {
  const [animationArrow, setAnimationArrow] = useState(true);
  const animationArrowRef = useRef(animationArrow);
  animationArrowRef.current = animationArrow;

  useEffect(() => {
    if (!isMobile) {
      setInterval(() => {
        setAnimationArrow(animationArrowRef.current ? false : true);
      }, 1200);
    }
  }, []);
  return (
    <div className="main">
      <div className="main__grid"></div>

      <div className="main__image-container">
        <img
          className="main__image animate__animated animate__rotateIn"
          src="/images/haba.png"
          alt="imagen de adorno burbuja girando"
        />
        <div className="main__logo-container">
          <img
            className="main__logo main__logo--obt  animate__animated animate__zoomIn"
            src="/images/logo-otb.png"
            alt="imagen de adorno burbuja girando"
          />
          <img
            className="main__logo main__logo--ltw animate__animated animate__zoomIn"
            src="/images/logo-ltw.png"
            alt="imagen de adorno burbuja girando"
          />
        </div>
      </div>
      <div className="main__text-container">
        <div className={`main__text-section main__text-section--1  `}>
          <span className="main__text-1">INTEGRATE</span>
          <span className="main__container-1">
            <span className="main__text-2">your english </span>
            <span className="main__text-3">& </span>
          </span>
          <span className="main__text-4">science classes</span>
        </div>

        <div className={`main__text-section main__text-section--2 `}>
          <span className="main__text-12">
            <span className="main__text-5 ">A </span>
            <span className="main__text-6">JOINT</span>
            <img className="main__ico-brain" src="/images/ico-cerebro.png" />
          </span>
          <span className="main__text-7">PROJECT</span>
          <span className="main__text-8">developed specifically</span>
          <div className=" main__double-wrong">
            <span className="main__text-9">FOR </span>
            <span className="main__text-10">
              <span>bilingual</span>
              <span>learners</span>
            </span>
          </div>
        </div>

        <div className={`main__text-section main__text-section--3 `}>
          <span className="main__text-11">you’ve come to the</span>

          <span className="main__container">
            <span className="main__text-12 ">RIGHT </span>
            <span className="main__text-13">PLACE</span>
          </span>
          <div
            className={`main__arrow-down ${
              animationArrowRef.current
                ? "animate__animated animate__rubberBand"
                : " "
            } `}
          >
            <img src="/images/arrow-down.png" />
          </div>
        </div>

        {/* <div
        className="main__image animate__animated animate__rotateIn"
        src="/images/bubble2.png"
        alt="imagen de adorno burbuja girando" */}
      </div>
    </div>
  );
};

export default Main;
