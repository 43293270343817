import React from "react";
import BookPoint from "./BookPoint/BookPoint";

const Book = () => {
  return (
    <div className="book">
      <img className="book__background" src="/images/forma-02-mobile.png" />
      <img
        className="book__background-desktop"
        src="/images/forma-02-desktop.png"
      />
      <div className="book__points-container">
        <div className="book__book-point">
          <BookPoint
            initState={1}
            step="page1"
            page="/images/page1-a.png"
            pageb="/images/page1-b.png"
            text1="your"
            text2="ENGLISH & SCIENCE"
            text3="classes made easy"
            image="/images/point-image1.png"
            text4="What does that mean? Your English and Science content is connected, making your life easier."
            text4Desktop={
              <div className="book__book-point-text">
                <p className="book__book-point-text-block">
                  Created from scratch and with the collaboration of dozens of
                  bilingual teachers like you, these two projects are designed
                  to facilitate cross-curricular learning.
                </p>
                <p className="book__book-point-text-block">
                  <span>What does that mean? </span>
                  Your English and Science content is connected, making your
                  life easier. It also helps you to:
                </p>
                <ul>
                  <li>
                    Reinforce key science content in the English classroom
                  </li>
                  <li>
                    Recycle and practise key language in the Science classroom
                  </li>
                </ul>
              </div>
            }
          />
        </div>
        <div className="book__book-point">
          <BookPoint
            initState={2}
            step="page2"
            page="/images/page2-a.png"
            pageb="/images/page2-b.png"
            text1="projects"
            text2="FOR LIFE"
            image="/images/point-image2.png"
            text4="A flexible way to introduce Learning Situations and Project Based Learning into your teaching."
            text4Desktop={
              <div className="book__book-point-text">
                <p className="book__book-point-text-block">
                  A flexible way to introduce Learning Situations and Project
                  Based Learning into your teaching.
                </p>
                <p className="book__book-point-text-block">
                  Building key competences, working collaboratively and
                  responding to real-world challenges, these projects are
                  designed to set your learners up for life.
                </p>
              </div>
            }
          />
        </div>
        <div className="book__book-point">
          <BookPoint
            initState={1}
            step="page3"
            page="/images/page3-a.png"
            pageb="/images/page3-b.png"
            text1="inclusive"
            text2="BILINGUAL LEARNING"
            image="/images/point-image3.png"
            text4="We want every child to learn to the very best of their ability. That’s why we’ve created extra resources for assessment and mixed ability classers, as well as family support material."
            text4Desktop={
              <div className="book__book-point-text">
                <p className="book__book-point-text-block">
                  Even Better Together is much more than just textbook teaching.
                  We want every child to learn to the very best of their
                  ability, that’s why we’ve created:
                </p>
                <ul>
                  <li>
                    Additional material at multiple levels for mixed ability
                    classes.
                  </li>
                  <li>
                    A range of assessment resources to help guide learning and
                    provide feedback on areas of improvement.{" "}
                  </li>
                  <li>
                    Family support material to involve family members in the
                    learning process.{" "}
                  </li>
                </ul>
              </div>
            }
          />
        </div>
        <div className="book__book-point">
          <BookPoint
            initState={2}
            step="page4"
            page="/images/page4-a.png"
            pageb="/images/page4-b.png"
            text1="complete"
            text2="DIGITAL SOLUTIONS"
            image="/images/point-image4.png"
            text4="We know how important quality digital support is, now more than ever. That’s why we’ve created digital material designed to enrich the bilingual learning experience."
            text4Desktop={
              <div className="book__book-point-text">
                <p className="book__book-point-text-block">
                  We know how important quality digital support is, now more
                  than ever. That’s why we’ve created:
                </p>
                <ul>
                  <li>
                    Shared access to all your digital Science and English
                    content.
                  </li>
                  <li>
                    Rich audio, video and visual resources. All designed to
                    enrich the bilingual learning experience.
                  </li>
                </ul>
                <p className="book__book-point-text-block">
                  So if your teaching scenario changes, don’t worry. We’ve got
                  you covered.
                </p>
              </div>
            }
          />
        </div>
        <div className="book__book-point">
          <BookPoint
            initState={1}
            step="page5"
            page="/images/page5-a.png"
            pageb="/images/page5-b.png"
            image="/images/point-image5.png"
            text1="inspiring learners to create a"
            text2="BETTER WORLD"
            text4="Cooperative learning, social and emotional learning and the UN’s Sustainable Development Goals (SDGs) are all developed."
            text4Desktop={
              <div className="book__book-point-text">
                <p className="book__book-point-text-block">
                  Cooperative learning shows that learning is EVEN BETTER when
                  we work TOGETHER.
                </p>
                <ul>
                  <li>
                    Additional material at multiple levels for mixed ability
                    classes.
                  </li>
                  <li>
                    A story at the heart of every unit that presents a shared
                    Social and Emotional Learning (SEL) syllabus.
                  </li>
                  <li>
                    A story at the heart of every unit that presents a shared
                    Social and Emotional Learning (SEL) syllabus.
                  </li>
                </ul>
              </div>
            }
          />
        </div>
      </div>
      <img
        className="book__background-bottom"
        src="/images/forma-02-mobile.png"
      />
      <img
        className="book__background-bottom-desktop"
        src="/images/forma-02-desktop.png"
      />
    </div>
  );
};

export default Book;
