import PropTypes from "prop-types";
import React from "react";

const Button = ({ text, variant, onClick, color, className, type }) => {
  const renderButton = () => (
    <button
      className={`button button--${variant} button--color-${color} ${className}`}
      onClick={onClick}
      type={type}
    >
      <div className="button__text">{text}</div>
    </button>
  );

  return renderButton();
};

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(["default", "inline"]),
  color: PropTypes.oneOf(["primary", "transparent"]),
};

Button.defaultProps = {
  text: "Title",
  variant: "default",
  color: "primary",
  type: "button",
};

export default Button;
