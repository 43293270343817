import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const InputText = React.forwardRef(
  ({ error, label, onChange, placeholder, type, ...props }, ref) => {
    return (
      <div
        className={clsx("input-text", {
          "input-text--error": !!error,
        })}
      >
        {label && <label className="input-text__label">{label}</label>}
        <div className="input-text__input-container">
          <input
            ref={ref}
            className={clsx("input-text__input")}
            type={type}
            {...props}
          />
        </div>
        {error && <span className="input-text__error">{error}</span>}
      </div>
    );
  }
);

InputText.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

InputText.defaultProps = {
  onChange: () => null,
  type: "text",
};

export default InputText;
