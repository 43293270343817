import axios from "axios";

export function sendContact(params) {
  return axios.post(window.URL_CONTACT, params, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `%JUli~Rq}4`,
    },
  });
}
