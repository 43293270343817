import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import InputText from "../../atoms/Input/InputText";
import { useForm, Controller } from "react-hook-form";
import Button from "../../atoms/Button/Button";
import { Checkbox } from "@material-ui/core";
import { sendContact } from "../../../services/Contact";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogDefault = ({ open, onClose, ...props }) => {
  const [openMessageOk, setopenMessageOk] = useState(false);
  const [openMessageFail, setopenMessageFail] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      await sendContact(data);
      resetForm();
      setopenMessageOk(true);
    } catch (e) {
      setopenMessageFail(true);
    }
    onClose();
  };

  function handleCloseOk() {
    setopenMessageOk(false);
  }

  function handleCloseFail() {
    setopenMessageFail(false);
  }

  function resetForm() {
    reset({
      name: "",
      surname: "",
      email: "",
      phone: "",
      school: "",
      region: "",
      ["science-book"]: "",
      ["elt-book"]: "",
      checkbox: false,
    });
  }

  return (
    <div className={clsx("dialog")}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        className={clsx("dialog")}
        {...props}
      >
        <DialogContent>
          <div className="dialog-contact__content">
            <div className="dialog-contact__content-title">
              <span className="dialog-contact__want">WANT TO KNOW</span>
              <span className="dialog-contact__more">MORE?</span>
              <span className="dialog-contact__text">
                contact us for a virtual
              </span>
              <span className="dialog-contact__text">
                or face-to-face presentation
              </span>
            </div>
            <div className="dialog-contact__form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputText
                  {...register("name", { required: true })}
                  label="Name*"
                />
                {errors.name && (
                  <span className="dialog-contact__error">
                    This field is required
                  </span>
                )}

                <InputText
                  {...register("surname", { required: true })}
                  label="Surname*"
                />
                {errors.surname && (
                  <span className="dialog-contact__error">
                    This field is required
                  </span>
                )}

                <InputText
                  {...register("email", { required: true })}
                  label="Email*"
                />
                {errors.email && (
                  <span className="dialog-contact__error">
                    This field is required
                  </span>
                )}

                <InputText {...register("phone")} label="Mobile phone" />
                <InputText
                  {...register("school", { required: true })}
                  label="School*"
                />
                {errors.school && (
                  <span className="dialog-contact__error">
                    This field is required
                  </span>
                )}

                <InputText
                  {...register("region", { required: true })}
                  label="Region*"
                />
                {errors.region && (
                  <span className="dialog-contact__error">
                    This field is required
                  </span>
                )}

                <InputText
                  {...register("science-book")}
                  label="Which Science textbooks are you currently using?"
                />
                <InputText
                  {...register("elt-book")}
                  label="Which ELT textbook are you currently using?"
                />
                <div className="dialog-contact__accept">
                  {/* <InputCheckbox
                    label="I agree to the data protection policy"
                    {...register("accept")}
                  /> */}
                  <Controller
                    name="checkbox"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <div className="dialog-contact__checkbox">
                          <Checkbox {...field} checked={field.value} />
                          <span className="dialog-contact__checkbox-label">
                            I agree to the{" "}
                            <a
                              href="https://www.bilingualbyme.com/privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              data protection policy
                            </a>
                          </span>
                        </div>
                      );
                    }}
                  />
                  {errors.checkbox && (
                    <span className="dialog-contact__error">
                      You must accept the data protection policy
                    </span>
                  )}
                </div>
                <div className="dialog-contact__button">
                  <Button type="submit" text="SEND" />
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openMessageOk}
        autoHideDuration={6000}
        onClose={handleCloseOk}
      >
        <Alert severity="success">
          Your data have reached us successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openMessageFail}
        autoHideDuration={6000}
        onClose={handleCloseFail}
      >
        <Alert severity="error">
          An error has occurred, the data could not be sent!
        </Alert>
      </Snackbar>
    </div>
  );
};

DialogDefault.propTypes = {
  open: PropTypes.bool,
};

DialogDefault.defaultProps = {
  open: false,
};

export default DialogDefault;
